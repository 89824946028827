.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.logo {
  width: 120px;
  height: 60px;
  background: #001529;
  float: left;
  color: #fff
}
